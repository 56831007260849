<template>
  <v-card>
    <v-card-text>
      <a
        class="market-button"
        href="https://apps.apple.com/tr/app/robosay/id6446150573"
        v-if="isIOS"
      >
        RoboSAY<br />iOS uygulamasını kullanın
      </a>
      <a
        class="market-button"
        href="https://play.google.com/store/apps/details?id=tr.com.robosay&hl=tr"
        v-else
      >
        RoboSAY<br />Android uygulamasını kullanın
      </a>
    </v-card-text>
  </v-card>
</template>

<style lang="scss" scoped>
.v-card {
  min-height: 100vh;
  text-align: center;
}

.v-card__text,
.market-button {
  height: 100%;
}

.market-button {
  font-size: 24px;
  border: 1px solid #e91e63;
  color: #e91e63;
  border-radius: 15px;
  display: block;
  padding: calc((80vh - 184px) / 2) 10px;
  line-height: 60px;
}
</style>

<script>
export default {
  name: "MobileAppLink",
  mounted() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    this.isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
  },
  data() {
    return {
      isIOS: false,
    };
  },
};
</script>
